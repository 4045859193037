import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.image ? '1fr 1fr' : '1fr')};
  grid-column-gap: 40px;
  align-items: center;
  margin: 0 auto 50px auto;
  padding: 48px;
  max-width: 1140px;
  min-height: 472px;
  background: ${props => (props.theme === 'light' ? '#ffffff' : '#091d2d')};
  border-radius: 3px;
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    padding: 48px 16px;
    border-radius: 0px;
    .content {
      order: 1;
    }
    .imgWrapper {
      order: 2;
    }
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: ${props => (props.theme === 'light' ? '#000000' : '#ffffff')};
  }
  p {
    width: ${props => (props.image ? '100%' : '50%')};
    margin-bottom: 24px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    color: ${props => (props.theme === 'light' ? '#000000' : '#ffffff')};
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }
`;
