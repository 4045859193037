import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Button from 'components/button';

import { Container } from './cta.css';

const Cta = ({ cta, theme = 'dark', position = 'left' }) => {
  const { title, content, image, action } = cta;

  if (position === 'left') {
    return (
      <Container image={image} theme={theme}>
        <div className="d-flex flex-column align-items-center justify-content-center content">
          <h3 className="text-center mb-4">{title}</h3>
          <p className={`text-center ${!image && 'mx-auto'}`}>{content}</p>
          {action && <Button link="/contact">{action}</Button>}
        </div>
        {image && (
          <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
        )}
      </Container>
    );
  } else {
    return (
      <Container image={image} theme={theme}>
        {image && (
          <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
        )}
        <div className="d-flex flex-column align-items-center justify-content-center content">
          <h3 className="text-center mb-4">{title}</h3>
          <p className={`text-center ${!image && 'mx-auto'}`}>{content}</p>
          {action && <Button link="/contact">{action}</Button>}
        </div>
      </Container>
    );
  }
};

Cta.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default Cta;
